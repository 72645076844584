import Vue from "vue";
import persistentState from "vue-persistent-state";
import App from "./App";
import initialState from "./InitialState";

Vue.use(persistentState, initialState);

const app = new Vue(App);

window.ref = app;
