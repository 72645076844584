module.exports = {
  settings: {
    odds: 10,
    lockers: [true, true, true, true, true, true],
    winningCombos: ["2346", "4830", "1169", "6318", "6454", "2091"],
    eventName: "THSCA 2022",
  },
  results: {
    tries: 0,
    winners: 0,
  },
};
