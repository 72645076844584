module.exports = {
  name: "App",
  el: "#app",
  data: {
    showSettings: false,
    combo: null,
    locker: null,
    isWinner: null,
    view: "start",
  },
  mounted() {
    this.updateViewFromHash();
    window.addEventListener("hashchange", this.updateViewFromHash);
  },
  methods: {
    updateViewFromHash() {
      let hash = new URL(window.location).hash;
      if (hash) {
        if (hash.substring(1) === "callback") {
          this.showView("entry");
        } else {
          this.showView(hash.substring(1));
        }

        // clear the hash
        window.history.replaceState({}, "", "/");
      }
    },

    showView(id) {
      this.view = id;
    },

    toggleSettings() {
      this.showSettings = !this.showSettings;
    },

    draw() {
      this.isWinner = Math.random() <= this.oddsDecimal;

      this.locker = this.pickRandomActiveLocker();

      if (this.isWinner) {
        this.results.winners++;
        this.combo = this.settings.winningCombos[this.locker];
      } else {
        this.combo = this.generateNonWinningCode(this.locker);
      }
      this.results.tries++;
    },

    pickRandomActiveLocker() {
      let r = Math.floor(Math.random() * this.activeLockers.length);
      return this.activeLockers[r];
    },

    generateNonWinningCode(locker) {
      let code = "";
      let digits = 4;
      while (code.length < digits) {
        code += "" + Math.floor(Math.random() * 10);
      }
      if (code === this.settings.winningCombos[locker]) {
        code = this.generateNonWinningCode(locker);
      }
      return code;
    },

    clearResults() {
      confirm("Are you sure?");
      this.results.tries = 0;
      this.results.winners = 0;
    },

    reset() {
      this.showView("start");
      this.combo = null;
    }
  },
  computed: {
    activeLockers() {
      let active = [];
      for (let i = 0; i < this.settings.lockers.length; i++) {
        if (this.settings.lockers[i]) {
          active.push(i);
        }
      }
      return active;
    },

    oddsDecimal() {
      return this.settings.odds / 100;
    }
  },
};
